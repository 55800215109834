<template>
  <moe-page title="查看拼团活动">
    <div class="moe-scroll-body">
      <div class="moe-scroll-body_content">
        <div class="moe-scroll-body_content_scroll">
          <moe-card class="mb-20">
            <template slot="header">
              基本信息
            </template>
            <moe-form :showback="false" :showTool="false">
              <moe-describe-list title="" :col="1">
                <moe-describe-item label="活动名称"> {{ activityParams.name }} </moe-describe-item>
                <moe-describe-item label="活动时间"> {{ activityParams.startTime }} 至 {{ activityParams.endTime }}
                </moe-describe-item>
                <moe-describe-item label="成团人数"> {{ activityParams.num }} </moe-describe-item>
                <moe-describe-item label="成团期限"> {{ `${activityParams.deadline} 小时` }} </moe-describe-item>
                <moe-describe-item label="团长返现"> {{ activityParams.cashback ? '是' : '否' }} </moe-describe-item>
                <moe-describe-item label="商品详情页展示待成团订单">{{ $moe_format.getVisibleList(activityParams.visible) }}</moe-describe-item>
                <moe-describe-item label="自动成团">{{ $moe_format.getVisibleList(activityParams.auto) }}</moe-describe-item>
                <moe-describe-item label="自动成团时间" v-if="activityParams.auto">{{ `开团后 ${activityParams.autoTime} 小时` }}</moe-describe-item>
              </moe-describe-list>
            </moe-form>
          </moe-card>

          <moe-card>
            <template slot="header">
              基本信息
            </template>

            <div style="height: 700px;min-height: 700px;" class="df fdc">
              <moe-table :numberShow="false" :data="activityParams.goods" :mode="false" :params="{ pageNum: 1 }">
                <el-table-column label="商品信息" min-width="200">
                  <template slot-scope="{ row }">
                    <div class="df aic">
                      <moe-image :src="row.coverUrl" width="80px" height="80px" />
                      <div class="ml-10 df fdc aifs">
                        <p class="fwb tal">{{ row.name }}</p>
                        <p class="font-12 color-info">商品ID: {{ row.id }}</p>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="销售价" min-width="150">
                  <template slot-scope="{ row }">
                    {{ `¥ ${row.minPrice}` }}{{ $moe_math.mathGreaterThan(row.maxPrice, row.minPrice) ? ` - ¥ ${row.maxPrice}` : '' }}
                  </template>
                </el-table-column>

                <el-table-column label="拼团价" min-width="80">
                  <template slot-scope="{ row }">
                    {{ `￥ ${row.minPrice2}` }}{{ $moe_math.mathGreaterThan(row.maxPrice2, row.minPrice2) ? ` - ¥ ${row.maxPrice2}` : '' }}
                  </template>
                </el-table-column>

                <el-table-column label="团长返现金额" min-width="150" v-if="activityParams.cashback">
                  <template slot-scope="{ row }">
                    {{ `￥ ${row.minCashbackAmount}` }}{{ $moe_math.mathGreaterThan(row.maxCashbackAmount, row.minCashbackAmount) ? ` - ¥ ${row.maxCashbackAmount}` : '' }}
                  </template>
                </el-table-column>

                <el-table-column label="库存" prop="stock" min-width="80" />

                <el-table-column label="操作" width="300" fixed="right">
                  <div class="moe-table_btns" slot-scope="{ row, $index }">
                    <el-button icon="el-icon-document" size="small" type="success" @click="queryShopGoodsById(row, $index)">查看设置</el-button>
                  </div>
                </el-table-column>
              </moe-table>
            </div>
          </moe-card>

          <moe-form :showBack="false">
            <template slot="tool">
              <el-button type="primary" @click="$moe_coordinator.navigateBack()">关闭</el-button>
            </template>
          </moe-form>
        </div>
      </div>
    </div>
    <moe-dialog :show="showDialog" title="拼团价设置" width="70%" @close="handleDialogClose()">
      <div class="dialog_content">
        <div style="height: 500px;min-height: 500px;" class="df fdc">
          <moe-table :numberShow="false" :data="goodsListSpecForm.list" :mode="false" :params="{ pageNum: 1 }">
            <el-table-column :show-overflow-tooltip="true" v-for="(item, index) in tableHeaderList" :key="index"
              :label="tableHeaderList[index].attr" :prop="tableHeaderList[index].attr" />

            <el-table-column label="商品信息" min-width="200">
              <template slot-scope="{ row }">
                <div class="df aic">
                  <moe-image :src="row.coverUrl" width="80px" height="80px" />
                  <div class="ml-10 df fdc aifs">
                    <p class="fwb tal">{{ row.goodsName }}</p>
                    <p class="font-12 color-info">商品ID: {{ row.goodsId }}</p>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="SKU" min-width="200">
              <template slot-scope="{ row }">
                <div class="df aic jcc">
                  <moe-tag class="mr-5" v-for="(item, index) in row.specValueStr.split(',')" :key="index">{{ item }}</moe-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="仓库编号" prop="identifier" min-width="150" :show-overflow-tooltip="true" />
            <el-table-column label="库存" prop="stock" min-width="80" />
            <el-table-column label="活动限购" prop="quota" min-width="80" />
            <el-table-column label="原价" min-width="150">
              <template slot-scope="{ row }">
                {{ `¥ ${row.salePrice}` }}
              </template>
            </el-table-column>
            <el-table-column label="拼团价" min-width="150">
              <template slot-scope="{ row }">
                {{ `¥ ${row.price}` }}
              </template>
            </el-table-column>
            <el-table-column label="团长返现" min-width="150" prop="cashbackAmount" v-if="activityParams.cashback">
              <template slot-scope="{ row }">
                {{ `¥ ${row.cashbackAmount}` }}
              </template>
            </el-table-column>
          </moe-table>
        </div>
      </div>
      <template slot="footer">
        <el-button @click="handleDialogClose()">关闭</el-button>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
export default {
  name: 'ActivityManageGroupDetail',
  data() {
    return {
      activityParams: {
        id: '',
        name: '',
        startTime: '',
        endTime: '',
        num: 2,
        deadline: '',
        cashback: false,
        visible: false,
        auto: false,
        autoTime: '',
        goods: [],
      },
      goodsListSpecForm: {
        list: [],
      },
      showDialog: false,
    }
  },
  methods: {
    handleDialogClose() {
      this.showDialog = false;
    },
    /** 查询商品详情 */
    queryShopGoodsById(row, index) {
      this.$moe_api.GOODS_API.queryShopGoodsById({ id: row.id }).then((data) => {
        if (data.code === 200) {
          let r = data.result;

          let goodsItem = this.$moe_lodash.cloneDeepData(r.goodsItem);
          this.goodsListSpecForm.list = this.$moe_lodash.cloneDeepData(this.activityParams.goods[index].skuConfigs).map((item) => {
            let findItem = goodsItem.find(({ id }) => item.goodsItemId === id);
            return {
              ...findItem,
              ...item,
              goodsName: r.name,
            }
          })
          this.showDialog = true;
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 获取拼团活动详情 */
    getGroupActivityDetail() {
      this.$moe_api.GROUPACTIVITY_API.getGroupActivityDetail({ id: this.$route.query.id }).then((data) => {
        if (data.code === 200) {
          let r = data.result;
          this.activityParams = {
            id: r.id,
            name: r.name,
            startTime: r.startTime,
            endTime: r.endTime,
            num: r.num,
            deadline: r.deadline,
            cashback: r.cashback,
            visible: r.visible,
            auto: r.auto,
            autoTime: r.autoTime,
            goods: r.goods.map((item) => {
              return {
                ...item,
                name: '',
                coverUrl: '',
                minPrice: '',
                maxPrice: '',
                minPrice2: item.skuConfigs.map(({ price }) => Number(price)).sort((a, b) => a - b)[0],
                maxPrice2: item.skuConfigs.map(({ price }) => Number(price)).sort((a, b) => b - a)[0],
                minCashbackAmount: item.skuConfigs.map(({ cashbackAmount }) => Number(cashbackAmount)).sort((a, b) => a - b)[0],
                maxCashbackAmount: item.skuConfigs.map(({ cashbackAmount }) => Number(cashbackAmount)).sort((a, b) => b - a)[0],
              }
            })
          };
          if (r.goods.length) {
            this.getQueryShopGoodsByIds(r.goods);
          }
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 根据商品ID获取商品列表 */
    getQueryShopGoodsByIds(goods) {
      this.$moe_api.GOODS_API.getQueryShopGoodsByIds({ ids: goods.map(({ id }) => id).join(',') }).then((data) => {
        if (data.code === 200) {
          let result = data.result;
          this.activityParams.goods.forEach((item) => {
            let findItem = result.find(({ id }) => item.id === id);
            item.stock = findItem.stock || 0;
            item.name = findItem.name || '';
            item.coverUrl = findItem.coverUrl || '';
            item.minPrice = findItem.minPrice || '';
            item.maxPrice = findItem.maxPrice || '';
          })
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.getGroupActivityDetail();
    }
  }
}
</script>

<style lang="scss" scoped></style>